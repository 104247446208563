import { Organisation } from '@/lib/api-dots';

// Capitalizes the first letter of the city name and converts the rest to lowercase.
export const formatCityName = (city: string): string => {
  return city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
};

// Generates an array of labels based on the properties of the organisation.
export const generateOrganisationLabels = (
  organisation: Organisation,
  t: (key: string) => string,
): string[] => {
  const labels: string[] = [];
  if (organisation.KwaliteitslabelTonen) {
    labels.push(t('qualityLabel'));
  }
  if (organisation.RegisterGovernance_OK) {
    labels.push(t('governancecode'));
  }
  if (organisation.Raadslid) {
    labels.push(t('socialCouncilWork'));
  }
  return labels;
};

// Opens organisation's website in a new browser tab.
// If the website URL does not start with 'http://' or 'https://', it prepends 'http://'.
export const openOrganisationWebsite = (website: string): void => {
  let url = website;
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`;
  }
  window.open(url, '_blank');
};

// Initiates a phone call to the specified organisation's phone number.
// Constructs a tel link and opens it in the same window.
export const callOrganisationNumber = (tel: string): void => {
  const phoneNumber = `tel:${tel}`.replace(/[\s()-]/g, '');
  window.open(phoneNumber, '_self');
};

// Opens the default email client with a pre-filled email address.
export const sendOrganisationEmail = (email: string): void => {
  const mailtoLink = `mailto:${email}`;
  window.open(mailtoLink, '_self');
};
