import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

interface ContainerProps {
  $positionFixed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: ${(props) => (props.$positionFixed ? 'fixed' : 'none')};
`;

export const Page = styled.div`
  display: flex;
  height: calc(100vh - ${(props) => props.theme.dimensions.headerHeight}px);

  ${breakpoints.combinedTablet} {
    height: calc(100vh - ${(props) => props.theme.dimensions.smallScreenHeaderHeight}px);
    flex-direction: column;
  }
`;
