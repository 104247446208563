import styled from 'styled-components';

export const IconButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border: none;
  cursor: pointer;

  &:hover,
  &:focus-within,
  &:active {
    svg {
      fill: ${(props) => props.theme.colors.iconActive};
    }
  }
`;

export const IconBox = styled.span<{ $iconColor?: string }>`
  display: flex;
  svg {
    fill: ${(props) => props.$iconColor || props.theme.colors.iconActive};
  }
`;
