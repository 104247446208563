/* eslint-disable max-len */

// Get a full URL based on a key.
export const getEnvUrl = (key: string): string => {
  let url = '';
  switch (key.toUpperCase()) {
    case 'CURRENT':
      url = String(process.env.NEXT_PUBLIC_CURRENT);
      break;
    case 'AGENDA':
      url = String(process.env.NEXT_PUBLIC_AGENDA);
      break;
    case 'OFFER':
      url = String(process.env.NEXT_PUBLIC_OFFER);
      break;
    case 'ABOUTUS':
      url = String(process.env.NEXT_PUBLIC_ABOUTUS);
      break;
    case 'IMPACT':
      url = String(process.env.NEXT_PUBLIC_IMPACT);
      break;
    case 'CONTACT':
      url = String(process.env.NEXT_PUBLIC_CONTACT);
      break;
    case 'LOGIN':
      url = String(process.env.NEXT_PUBLIC_LOGIN);
      break;
    case 'BECAMEMEMBER':
      url = String(process.env.NEXT_PUBLIC_BECAMEMEMBER);
      break;
  }

  if (url?.startsWith('/')) {
    return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL?.replace(/\/+$/, '')}/${url.replace(/^\/+/, '')}`;
  }

  return url || '';
};
