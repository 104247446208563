'use client';

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@images/Close.svg';
import FilterIcon from '@images/Filter.svg';

import { LabelButton, PrimaryButton } from '@/components/Button';
import { useMemberContext } from '@/context/useMemberContext';
import { useIsMobileOrTablet } from '@/hooks/useIsMobileOrTablet';
import { useOrganisations } from '@/hooks/useOrganisations';

import { FilterContainer, FilterHeader, FilterLabelButtonWrapper, FilterModal } from './styles';

/**
 * Filter component for filtering organisations based on
 * quality label, governance code and social council work
 */

export const Filter = () => {
  const isSmallScreen = useIsMobileOrTablet();
  const { t } = useTranslation('common');
  const {
    member,
    setMember,
    search,
    qualityLabel,
    setQualityLabel,
    governanceCode,
    setGovernanceCode,
    socialCouncilWork,
    setSocialCouncilWork,
    showFilterModal,
    setShowFilterModal,
  } = useMemberContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { organisations } = useOrganisations(
    search,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );

  // Filter items (labels) with active states individually
  const filterItems = [
    { id: 'qualityLabel', label: t('qualityLabel'), isActive: qualityLabel },
    { id: 'governanceCode', label: t('governancecode'), isActive: governanceCode },
    { id: 'socialCouncilWork', label: t('socialCouncilWork'), isActive: socialCouncilWork },
  ];

  const hasFilter = useCallback(() => {
    return Boolean(search) || qualityLabel || governanceCode || socialCouncilWork;
  }, [search, qualityLabel, governanceCode, socialCouncilWork]);

  // Toggle specific filters based on id
  const handleFilterToggle = (id: string) => {
    switch (id) {
      case 'qualityLabel':
        setQualityLabel(!qualityLabel);
        break;
      case 'governanceCode':
        setGovernanceCode(!governanceCode);
        break;
      case 'socialCouncilWork':
        setSocialCouncilWork(!socialCouncilWork);
        break;
      default:
        console.log(`Error: unknown filter id: ${id}`);
    }
  };

  // Deselect member when it is not part of the filter.
  useEffect(() => {
    if (
      hasFilter() &&
      organisations.length > 0 &&
      !organisations.some((org) => org.ExtKey === member?.ExtKey)
    ) {
      setMember(null);
    }
  }, [member, organisations, hasFilter, setMember]);

  return (
    <FilterContainer>
      {isSmallScreen ? (
        <>
          <PrimaryButton
            icon={showFilterModal ? <CloseIcon /> : <FilterIcon />}
            label={showFilterModal ? t('closeFilter') : t('filters')}
            onClick={() => setShowFilterModal(!showFilterModal)}
            isActive={showFilterModal}
          />
          {showFilterModal && (
            <FilterModal>
              {filterItems.map((item) => (
                <FilterLabelButtonWrapper key={item.id}>
                  <LabelButton
                    label={item.label}
                    onClick={() => handleFilterToggle(item.id)}
                    isActive={item.isActive}
                  />
                </FilterLabelButtonWrapper>
              ))}
            </FilterModal>
          )}
        </>
      ) : (
        <>
          <FilterHeader>{t('filters')}</FilterHeader>
          <FilterModal>
            {filterItems.map((item) => (
              <LabelButton
                key={item.id}
                label={item.label}
                onClick={() => handleFilterToggle(item.id)}
                isActive={item.isActive}
              />
            ))}
          </FilterModal>
        </>
      )}
    </FilterContainer>
  );
};
