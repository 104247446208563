import { useEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if window is defined to ensure this code runs only on the client-side
    if (typeof window !== 'undefined') {
      // Access window inside the useEffect hook
      const mediaQuery = window.matchMedia('(max-width: 500px)');

      // Set initial value
      setIsMobile(mediaQuery.matches);

      // Add listener for changes
      const listener = () => {
        setIsMobile(mediaQuery.matches);
      };
      mediaQuery.addListener(listener);

      // Clean up by removing the listener when the component unmounts
      return () => {
        mediaQuery.removeListener(listener);
      };
    }
  }, []);

  return isMobile;
};
