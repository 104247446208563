'use client';

import CheckIcon from '@images/Check.svg';
import TagIcon from '@images/Tag.svg';

import { ButtonProps } from './buttonType';
import { IconWrapper, LabelWrapper } from './styles';

export const LabelButton = ({ label, onClick, isActive = false }: ButtonProps) => {
  return (
    <LabelWrapper $isActive={isActive} onClick={onClick}>
      <IconWrapper $isActive={isActive}>{isActive ? <CheckIcon /> : <TagIcon />}</IconWrapper>
      {label}
    </LabelWrapper>
  );
};
