'use client';

import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowIcon from '@images/Chevron.svg';
import CloseIcon from '@images/Close.svg';
import SearchIcon from '@images/Search.svg';

import { Filter } from '@/components/Filter';
import { Coordinates } from '@/context/MemberProvider';
import { useMemberContext } from '@/context/useMemberContext';
import { useIsMobileOrTablet } from '@/hooks/useIsMobileOrTablet';
import { useOrganisations } from '@/hooks/useOrganisations';
import { MapBoxResult, useSearchMapBox } from '@/hooks/useSearchMapBox';
import { Organisation } from '@/lib/api-dots';
import { lightTheme } from '@/styles/theme';
import { formatCityName } from '@/utils/organisationUtils';

import { IconButton } from '../Button';
import { SearchResultItem } from './SearchResult';
import {
  InputContainer,
  Scrollable,
  SearchContainer,
  SearchHeader,
  SearchInput,
  SearchPopup,
  SearchPopupResult,
  SearchResultHeader,
} from './styles';

/**
 * Search component
 */

export const Search = () => {
  const isSmallScreen = useIsMobileOrTablet();
  const { t } = useTranslation('common');
  const refInput = useRef<HTMLInputElement>(null);
  const {
    search,
    setSearch,
    isSearchPopupVisible,
    setIsSearchPopupVisible,
    coordinates,
    setCoordinates,
    showFilterModal,
    setMember,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  } = useMemberContext();
  const [keyword, setKeyword] = useState(
    search ?? (coordinates ? `${coordinates.latitude},${coordinates.longitude}` : ''),
  );

  const { organisations } = useOrganisations(
    keyword,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );
  const { results: resultsMapBox } = useSearchMapBox(keyword);

  /**
   * Main search functionality based on the current keyword
   * If the keyword matches coordinates format (e.g., "12.34,56.78"), updates coordinates
   * Otherwise, updates the search term
   * Clears member selection, blurs input, and hides search popup
   */
  const searchOnKeywords = () => {
    if (keyword.match(/[0-9.]+,[0-9.]+/i)) {
      const newCoordinates = keyword.split(',');
      if (newCoordinates[0] && newCoordinates[1]) {
        setCoordinates({ latitude: +newCoordinates[0], longitude: +newCoordinates[1] });
      }
    } else {
      setSearch(keyword);
    }
    setMember(null);
    setIsSearchPopupVisible(false);
  };

  // Triggers search when Enter key is pressed
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      searchOnKeywords();
    }
  };

  //Updates keyword state and clears search/coordinates if input is empty
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    if (event.target.value === '') {
      setSearch('');
      setCoordinates(null);
    }
    setIsSearchPopupVisible(true);
  };

  // Updates the map coordinates and triggers a search based on the input
  const goToCoordinates = (coordinates: Coordinates, name?: string) => {
    setCoordinates(coordinates);
    setKeyword(name ?? `${coordinates.latitude},${coordinates.longitude}`);
    setSearch('');
    setMember(null);
    setIsSearchPopupVisible(false);
    searchOnKeywords();
  };

  // Select city of a MapBox search result
  const openSearchResult = (result: MapBoxResult) => {
    if (result.place_type[0] === 'place') {
      setKeyword(result.text);
      goToCoordinates(result.coordinates);
    } else {
      goToCoordinates(result.coordinates, result.text);
    }
  };

  //Select an organisation from search results
  const openOrganisation = (org: Organisation) => {
    setMember(org);
    const coordinates = {
      latitude: +org.Latitude,
      longitude: +org.Longitude,
    };
    setCoordinates(coordinates);
    setKeyword(org.Company);
    setSearch('');
    setIsSearchPopupVisible(false);
  };

  // Clears all search state and input
  const clearInput = () => {
    setKeyword('');
    setSearch('');
    setCoordinates(null);
    setMember(null);

    if (!isSmallScreen) {
      setIsSearchPopupVisible(false);
    }
  };

  const handleCloseButtonClick = () => {
    setIsSearchPopupVisible(false);
  };

  return (
    <>
      <SearchContainer>
        <InputContainer>
          {isSmallScreen && isSearchPopupVisible && (
            <IconButton
              iconColor={lightTheme.colors.typographyHeading}
              icon={<ArrowIcon />}
              onClick={handleCloseButtonClick}
            />
          )}
          <SearchInput
            ref={refInput}
            type="text"
            placeholder={t('searchInput')}
            value={keyword}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={() => setIsSearchPopupVisible(true)}
            onFocus={() => setIsSearchPopupVisible(true)}
            onBlur={!isSmallScreen ? () => setIsSearchPopupVisible(false) : undefined}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          />
          {keyword || isSearchPopupVisible ? (
            <IconButton
              icon={<CloseIcon />}
              onClick={clearInput}
              iconColor={lightTheme.colors.typographyBody}
            />
          ) : (
            <SearchIcon fill={lightTheme.colors.typographyBody} />
          )}
        </InputContainer>
        <Filter />
      </SearchContainer>
      {isSearchPopupVisible && (
        <SearchPopup $showFilterModal={showFilterModal}>
          {keyword.length >= 3 && resultsMapBox.length > 0 && organisations.length > 0 && (
            <>
              <SearchHeader>
                <SearchResultHeader>
                  {t('searchResults')} {`(${resultsMapBox.length + organisations.length})`}
                </SearchResultHeader>
              </SearchHeader>
              <Scrollable>
                <SearchPopupResult>
                  {resultsMapBox.map((result) => (
                    <SearchResultItem
                      key={`mapbox-${result.id}`}
                      onClick={() => openSearchResult(result)}
                      mainText={<strong>{result.text}</strong>}
                      secondaryText={result.country}
                    />
                  ))}
                  {organisations.map((org, index) => (
                    <SearchResultItem
                      key={`org-${index}`}
                      onClick={() => openOrganisation(org)}
                      mainText={<strong>{org.Company}</strong>}
                      // eslint-disable-next-line max-len
                      secondaryText={`${org.Street} ${org.Housenumber}, ${org.AreaCode} ${formatCityName(org.City)}`}
                    />
                  ))}
                </SearchPopupResult>
              </Scrollable>
            </>
          )}
        </SearchPopup>
      )}
    </>
  );
};
