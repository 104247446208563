import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

interface MapProps {
  $visible: boolean;
}

export const MapContainer = styled.div<MapProps>`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;

  ${breakpoints.combinedTablet} {
    display: ${(props) => (props.$visible ? 'none' : 'flex')};
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const MarkerCicrcle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 2.625rem;
  height: 2.625rem;
  background-color: ${(props) => props.theme.colors.primary};
  opacity: 1;
  color: ${(props) => props.theme.colors.backgroundPrimary};
  cursor: pointer;
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};
`;

export const MarkerContent = styled.div`
  cursor: pointer;
`;
