import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

interface ListModalMobileViewProps {
  $hideListWhenSearchOpen: boolean;
}

export const ListContainer = styled.div<ListModalMobileViewProps>`
  display: ${(props) => (props.$hideListWhenSearchOpen ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 11rem;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};

  ${breakpoints.combinedTablet} {
    height: fit-content;
    flex: 0;
  }
`;

export const ListHeaderContainer = styled.div`
  padding-block: 1.5rem;
  padding-inline: 1rem;
  border-block: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  position: sticky;
  top: 0;
  z-index: 1;

  ${breakpoints.combinedTablet} {
    padding-block: 0;
    padding-bottom: 1.5rem;
    border-block: none;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.colors.border};
  }
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const ListSelectedHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ListHeader = styled.span`
  font-size: ${(props) => props.theme.typography3.fontSize};
  font-weight: ${(props) => props.theme.typography3.fontWeight};
  line-height: ${(props) => props.theme.typography3.lineHeight};
`;
