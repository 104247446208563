'use client';

import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'next/navigation';

import { Organisation } from '@/lib/api-dots';

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface MemberContextType {
  member: Organisation | null;
  setMember: (member: Organisation | null) => void;
  search: string;
  setSearch: (search: string) => void;
  isSearchPopupVisible: boolean;
  setIsSearchPopupVisible: (visible: boolean) => void;
  coordinates: Coordinates | null;
  setCoordinates: (coordinates: Coordinates | null) => void;
  qualityLabel: boolean;
  setQualityLabel: (qualityLabel: boolean) => void;
  governanceCode: boolean;
  setGovernanceCode: (governanceCode: boolean) => void;
  socialCouncilWork: boolean;
  setSocialCouncilWork: (socialCouncilWork: boolean) => void;
  showFilterModal: boolean;
  setShowFilterModal: (showFilterModal: boolean) => void;
  showResults: boolean | null;
  setShowResults: (showResults: boolean) => void;
}

const defaults: MemberContextType = {
  member: null,
  setMember: () => {},
  search: '',
  setSearch: () => {},
  isSearchPopupVisible: false,
  setIsSearchPopupVisible: () => {},
  coordinates: null,
  setCoordinates: () => {},
  qualityLabel: false,
  setQualityLabel: () => {},
  governanceCode: false,
  setGovernanceCode: () => {},
  socialCouncilWork: false,
  setSocialCouncilWork: () => {},
  showFilterModal: false,
  setShowFilterModal: () => {},
  showResults: false,
  setShowResults: () => {},
};
export const MemberContext = createContext<MemberContextType>(defaults);

export interface MemberProvider {
  children: ReactNode;
  preselectMember?: Organisation;
}

export const MemberProvider = ({ children, preselectMember }: MemberProvider) => {
  // get defaults from query string on full reload
  const searchParams = useSearchParams();

  const searchKeyword = searchParams?.get('search');
  const searchCoordinates = searchParams
    ?.get('coordinates')
    ?.split(',')
    ?.reduce((a, v, i) => ({ ...a, [i === 0 ? 'latitude' : 'longitude']: v }), {
      latitude: 0,
      longitude: 0,
    });

  const qualityLabelParam = searchParams?.get('kwaliteitslabel') === '1';
  const governanceCodeParam = searchParams?.get('governancecode') === '1';
  const socialCouncilWorkParam = searchParams?.get('sociaalraadswerk') === '1';

  const [member, setMember] = useState(preselectMember ?? defaults.member);
  const [search, setSearch] = useState(searchKeyword ?? defaults.search);
  const [isSearchPopupVisible, setIsSearchPopupVisible] = useState(defaults.isSearchPopupVisible);
  const [coordinates, setCoordinates] = useState<Coordinates | null>(
    searchCoordinates ?? defaults.coordinates,
  );
  const [qualityLabel, setQualityLabel] = useState(qualityLabelParam ?? defaults.qualityLabel);
  const [governanceCode, setGovernanceCode] = useState(
    governanceCodeParam ?? defaults.governanceCode,
  );
  const [socialCouncilWork, setSocialCouncilWork] = useState(
    socialCouncilWorkParam ?? defaults.socialCouncilWork,
  );
  const [showFilterModal, setShowFilterModal] = useState(defaults.showFilterModal);
  const [showResults, setShowResults] = useState(defaults.showResults);

  const createSlug = (org: Organisation) => {
    return org.Company.toLowerCase() // Convert to lowercase
      .trim() // Trim leading and trailing spaces
      .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with dashes
      .replace(/-+/g, '-'); // Remove consecutive dashes
  };

  // push query string to router
  useEffect(() => {
    let path = '/';
    const params: Record<string, string> = {};

    if (member) {
      path = `/lid/${member.ExtKey}/${createSlug(member)}/`;
    }
    if (search) params.search = search;
    if (coordinates) params.coordinates = `${coordinates.latitude},${coordinates.longitude}`;
    if (qualityLabel) params.kwaliteitslabel = qualityLabel ? '1' : '0';
    if (governanceCode) params.governancecode = governanceCode ? '1' : '0';
    if (socialCouncilWork) params.sociaalraadswerk = socialCouncilWork ? '1' : '0';
    const searchParams = new URLSearchParams(params);

    const searchParamString = searchParams.toString() ? `?${searchParams.toString()}` : '';
    const newUrl = `${path}${searchParamString}`;
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, search, coordinates, qualityLabel, governanceCode, socialCouncilWork]);

  // Hooks
  const value = useMemo(
    () => ({
      setShowResults,
      showResults,
      showFilterModal,
      setShowFilterModal,
      member,
      setMember,
      search,
      setSearch,
      isSearchPopupVisible,
      setIsSearchPopupVisible,
      coordinates,
      setCoordinates,
      qualityLabel,
      setQualityLabel,
      governanceCode,
      setGovernanceCode,
      socialCouncilWork,
      setSocialCouncilWork,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      showResults,
      setShowResults,
      showFilterModal,
      setShowFilterModal,
      member,
      setMember,
      search,
      setSearch,
      isSearchPopupVisible,
      setIsSearchPopupVisible,
      coordinates,
      setCoordinates,
      qualityLabel,
      setQualityLabel,
      governanceCode,
      setGovernanceCode,
      socialCouncilWork,
      setSocialCouncilWork,
    ],
  );

  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
};
