'use client';

import React from 'react';

import { Header } from '@/components/Header';
import { BottomModalList } from '@/components/List';
import { Main } from '@/components/Main';
import { Map } from '@/components/Map';
import { useMemberContext } from '@/context/useMemberContext';
import { useIsMobileOrTablet } from '@/hooks/useIsMobileOrTablet';

import { Container, Page } from './styles';

export const Home = () => {
  const isSmallScreen = useIsMobileOrTablet();
  const { isSearchPopupVisible } = useMemberContext();
  return (
    <Container $positionFixed={isSearchPopupVisible}>
      <Header />
      <Page>
        <Main />
        <Map />
        {/* Show List on tablets and mobile*/}
        {isSmallScreen && !isSearchPopupVisible ? <BottomModalList /> : null}
      </Page>
    </Container>
  );
};
