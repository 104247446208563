'use client';

import { List } from '@/components/List';
import { Search } from '@/components/Search';
import { useMemberContext } from '@/context/useMemberContext';
import { useIsMobileOrTablet } from '@/hooks/useIsMobileOrTablet';

import { MainContainer } from './styles';

export const Main = () => {
  const isSmallScreen = useIsMobileOrTablet();
  const { isSearchPopupVisible } = useMemberContext();

  return (
    <MainContainer $positionFixed={isSearchPopupVisible}>
      <Search />
      {/* Hide List if it's tablets or mobile */}
      {!isSmallScreen && <List />}
    </MainContainer>
  );
};
