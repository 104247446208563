'use client';

import { ReactNode } from 'react';

import LocationIcon from '@images/Location.svg';

import { lightTheme } from '@/styles/theme';

import {
  CityName,
  CountryName,
  CountryWrapper,
  IconWrapper,
  ResultCityName,
  SearchResult,
  SearchResultContent,
} from './styles';

type SearchResultItemProps = {
  onClick: () => void;
  mainText: ReactNode;
  secondaryText?: ReactNode;
};

/**
 * Display Search Results
 */

export function SearchResultItem({ onClick, mainText, secondaryText }: SearchResultItemProps) {
  return (
    <SearchResult onMouseDown={(e) => e.preventDefault()} onClick={onClick}>
      <SearchResultContent>
        <ResultCityName>
          <IconWrapper>
            <LocationIcon fill={lightTheme.colors.typographyHeading} />
          </IconWrapper>
          <CityName>{mainText}</CityName>
        </ResultCityName>
        <CountryWrapper>
          <CountryName>{secondaryText}</CountryName>
        </CountryWrapper>
      </SearchResultContent>
    </SearchResult>
  );
}
