import styled from 'styled-components';

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding-inline: ${(props) => props.theme.spacing(1)}px;
  padding-block: ${(props) => props.theme.spacing(0.5)}px;
  background-color: ${(props) => props.theme.colors.secondary};
  border: none;
  border-radius: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.colors.backgroundPrimary};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};
`;

export const IconContainer = styled.span`
  display: flex;
  svg {
    fill: ${(props) => props.theme.colors.backgroundPrimary};
  }
`;
