'use client';

import { ButtonProps } from './buttonType';
import { ButtonContainer, IconContainer } from './styles';

export const PrimaryButton = ({ icon, label, onClick, isActive = false }: ButtonProps) => {
  return (
    <ButtonContainer onClick={onClick} $isActive={isActive}>
      <IconContainer $isActive={isActive}>{icon}</IconContainer>
      {label}
    </ButtonContainer>
  );
};
