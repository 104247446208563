import styled, { css } from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

const headerNavigationLink = css`
  all: unset;
  color: ${(props) => props.theme.colors.typographyHeading};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};
  cursor: pointer;
  text-decoration: none;
  padding-block: 0.9rem;
  transition: color 0.3s linear;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

interface BurgerMenuProps {
  $isOpen: boolean;
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-inline: 1rem;
  padding-block: 1.5rem;
  height: ${(props) => props.theme.dimensions.headerHeight}px;
  box-sizing: border-box;

  ${breakpoints.combinedTablet} {
    height: ${(props) => props.theme.dimensions.smallScreenHeaderHeight}px;
    justify-content: space-between;
  }
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 8.5rem;
  height: 2.5rem;

  ${breakpoints.combinedTablet} {
    width: 6rem;
    height: 2.25rem;
  }
`;

export const HomeButton = styled.a`
  display: flex;
  justify-self: start;
  align-items: center;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  ${breakpoints.combinedTablet} {
    display: none;
  }
`;

export const HeaderButton = styled.a`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.typographyHeading};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }

  ${breakpoints.combinedTablet} {
    ${headerNavigationLink}
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  ${breakpoints.combinedTablet} {
    display: none;
  }
`;

export const LoginButton = styled.a`
  all: unset;
  cursor: pointer;
  padding-inline: 1rem;
  padding-block: 0.4rem;
  border-radius: 6.25rem;
  border: 2px solid ${(props) => props.theme.colors.border};
  color: ${(props) => props.theme.colors.typographyHeading};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    border: 2px solid ${(props) => props.theme.colors.primary};
  }
  ${breakpoints.combinedTablet} {
    ${headerNavigationLink}
  }
`;

export const BecameMember = styled.a`
  all: unset;
  cursor: pointer;
  padding-inline: 1rem;
  padding-block: 0.4rem;
  border-radius: 6.25rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.backgroundPrimary};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};

  &:hover {
    background-color: ${(props) => props.theme.colors.hover};
  }
  ${breakpoints.combinedTablet} {
    ${headerNavigationLink}
  }
`;

export const MobileNav = styled.div<BurgerMenuProps>`
  display: none;

  ${breakpoints.combinedTablet} {
    display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.backgroundPrimary};
    max-height: 85vh;
    width: 100%;
    text-align: left;
    padding-inline: 1rem;
    position: absolute;
    top: ${(props) => props.theme.dimensions.smallScreenHeaderHeight}px;
    right: 0;
    z-index: 1000;
  }
`;
