import { ApiError } from '@/lib/api-error';

export const REFRESH_INTERVAL = 15000;

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface FetchParams {
  // The URL to send the request to.
  url: RequestInfo;
  //  The HTTP method for the request (e.g., "GET", "POST").
  method?: Method;
  // The request body, which can be of any type.
  body?: unknown;
  // The caching mode for the request (e.g., "default", "no-store").
  cache?: RequestCache;
}

/**
 * Asynchronous function to fetch data from a specified URL.
 */
export default async function fetcher<JSON = unknown>({
  url,
  method = 'GET',
  body,
  cache = 'no-store',
}: FetchParams): Promise<JSON> {
  // Execute request
  const response = await fetch(url, {
    cache: cache,
    method: method ?? 'GET',
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  // If the server replies, there's always some data in json.
  // if there's a network error, it will throw at the previous line.

  // Response.ok is true when res.status is 2xx
  if (response.ok) {
    return method !== 'DELETE' ? await response.json() : null;
  }

  // Throw an error when server returns a error.
  let errorMessage: string;
  try {
    const { title } = await response.json();
    errorMessage = title;
  } catch (error) {
    errorMessage = await response.text();
  }
  throw new ApiError(errorMessage, response.status);
}
