import { useQuery } from '@tanstack/react-query';

import { FeatureCollection } from '@/lib/mapbox-dtos';
import fetcher from '@/utils/fetcher';

export interface MapBoxResult {
  id: string;
  text: string;
  place: string;
  country: string;
  place_type: string;
  coordinates: {
    longitude: number;
    latitude: number;
  };
}

/**
 * Hook for searching locations using MapBox Geocoding API
 */
export const useSearchMapBox = (keyword: string) => {
  // Trim and url encode
  keyword = keyword.trim();
  keyword = encodeURIComponent(keyword);

  // Search in types
  const types = [
    'place',
    'address',
    'country',
    'region',
    'postcode',
    'district',
    'locality',
    'neighborhood',
  ];

  const searchParams = new URLSearchParams({
    proximity: 'ip',
    types: types.join(','),
    access_token: String(process.env.NEXT_PUBLIC_MAPBOX_KEY),
    language: 'nl',
    country: 'nl',
  });

  // Fetch search results from the MapBox Geocoding API
  const { data, isPending } = useQuery<FeatureCollection, Error>({
    enabled: keyword.length >= 3,
    queryKey: [keyword],
    queryFn: () =>
      fetcher({
        // eslint-disable-next-line max-len
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${keyword}.json?${searchParams.toString()}`,
      }),
  });

  // Shape data to simple results
  const results = (data?.features ?? [])
    .map(
      (result) =>
        ({
          id: result.id,
          text: result.text,
          place: result.place_name,
          country: result.context?.find((c) => c.id.includes('country'))?.text ?? 'unknown',
          place_type: result.place_type?.[0] ?? 'unknown',
          coordinates: {
            longitude: result.geometry.coordinates[0],
            latitude: result.geometry.coordinates[1],
          },
        }) as MapBoxResult,
    )
    .filter(
      (result) =>
        // Double check that results are actually in Netherlands
        result.country.toLowerCase() === 'nederland' ||
        result.country.toLowerCase() === 'netherlands',
    );

  return {
    results,
    isPending,
  };
};
