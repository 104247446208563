/* eslint-disable indent */
import styled from 'styled-components';

interface ButtonProps {
  $isActive: boolean;
}

export const LabelWrapper = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: ${(props) => props.theme.spacing(1)}px;
  width: 100%;
  padding-inline: ${(props) => props.theme.spacing(1)}px;
  padding-block: ${(props) => props.theme.spacing(0.5)}px;
  background-color: ${(props) =>
    props.$isActive ? props.theme.colors.secondary : props.theme.colors.backgroundPrimary};
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  color: ${(props) =>
    props.$isActive ? props.theme.colors.backgroundPrimary : props.theme.colors.secondary};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};

  &:hover,
  &:focus-within,
  &:active {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.backgroundPrimary};

    svg {
      fill: ${(props) => props.theme.colors.backgroundPrimary};
    }
  }
`;

export const IconWrapper = styled.span<ButtonProps>`
  display: flex;
  svg {
    fill: ${(props) =>
      props.$isActive ? props.theme.colors.backgroundPrimary : props.theme.colors.secondary};
  }
`;
