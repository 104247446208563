'use client';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@images/Close.svg';

import { useMemberContext } from '@/context/useMemberContext';
import { useOrganisations } from '@/hooks/useOrganisations';
import { Organisation } from '@/lib/api-dots';
import { lightTheme } from '@/styles/theme';

import { IconButton } from '../Button';
import { ListCard } from './ListCard';
import {
  ListContainer,
  ListHeader,
  ListHeaderContainer,
  ListSelectedHeader,
  ScrollableContent,
} from './styles';

/**
 * @description List component for displaying a list of all organisations
 */

export const List = () => {
  const { t } = useTranslation('common');
  const {
    member,
    setMember,
    search,
    isSearchPopupVisible,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  } = useMemberContext();
  const { organisations } = useOrganisations(
    search,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );

  // Select a member
  const selectMember = (organisation: Organisation) => {
    setMember(organisation);
  };

  // Unselect the selected organisation
  const handleUnselect = () => {
    setMember(null);
  };

  return (
    <ListContainer $hideListWhenSearchOpen={isSearchPopupVisible}>
      <ListHeaderContainer>
        {member ? (
          <ListSelectedHeader>
            <ListHeader>{t('selectedMember')}</ListHeader>
            <IconButton
              icon={<CloseIcon />}
              iconColor={lightTheme.colors.typographyHeading}
              onClick={handleUnselect}
            />
          </ListSelectedHeader>
        ) : (
          <ListHeader>{`${t('memberList')} (${organisations.length})`}</ListHeader>
        )}
      </ListHeaderContainer>
      <ScrollableContent>
        {member ? (
          // Render only the selected organisation if `member` is set
          <ListCard key={member.ExtKey} organisation={member} isMemberSelected={Boolean(member)} />
        ) : (
          // Render all organisations if `member` is not set
          organisations?.map((organisation: Organisation, index: number) => (
            <ListCard
              key={`${organisation.Tel}-${index}`}
              organisation={organisation}
              onClick={() => selectMember(organisation)}
            />
          ))
        )}
      </ScrollableContent>
    </ListContainer>
  );
};
