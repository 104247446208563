'use client';

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sheet, type SheetRef } from 'react-modal-sheet';

import ChevronIcon from '@images/ChevronTop.svg';
import CloseIcon from '@images/Close.svg';

import { useMemberContext } from '@/context/useMemberContext';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useOrganisations } from '@/hooks/useOrganisations';
import { Organisation } from '@/lib/api-dots';
import { lightTheme } from '@/styles/theme';

import { IconButton } from '../Button';
import { ListCard } from './ListCard';
import {
  ListContainer,
  ListHeader,
  ListHeaderContainer,
  ListSelectedHeader,
  ScrollableContent,
} from './styles';

/**
 * @description Mobile List component
 */
export const BottomModalList = () => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();
  const {
    member,
    setMember,
    search,
    isSearchPopupVisible,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  } = useMemberContext();
  const { organisations } = useOrganisations(
    search,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );
  const ref = useRef<SheetRef>(null);
  const snapTo = (i: number) => ref.current?.snapTo(i);
  const [currentSnap, setCurrentSnap] = useState(1);

  // Select a member
  const selectMember = (organisation: Organisation) => {
    setMember(organisation);
    snapTo(1);
  };

  // Unselect the selected organisation
  const handleUnselect = () => {
    setMember(null);
    snapTo(2);
  };
  return (
    <ListContainer $hideListWhenSearchOpen={isSearchPopupVisible}>
      <Sheet
        isOpen
        ref={ref}
        snapPoints={isMobile ? [450, 350, 90] : [750, 400, 150]}
        onSnap={(index) => setCurrentSnap(index)}
        initialSnap={member ? 1 : 2}
        onClose={() => {
          snapTo(2);
        }}>
        <Sheet.Container>
          <Sheet.Header />
          <ListHeaderContainer>
            <ListSelectedHeader>
              {member ? (
                <>
                  <ListHeader>{t('selectedMember')}</ListHeader>
                  <IconButton
                    icon={<CloseIcon />}
                    iconColor={lightTheme.colors.typographyHeading}
                    onClick={handleUnselect}
                  />
                </>
              ) : (
                <>
                  <ListHeader>{`${t('memberList')} (${organisations.length})`}</ListHeader>
                  <IconButton
                    icon={currentSnap === 2 ? <ChevronIcon /> : <CloseIcon />}
                    iconColor={lightTheme.colors.typographyHeading}
                    onClick={() => (currentSnap === 2 ? snapTo(0) : snapTo(2))}
                  />
                </>
              )}
            </ListSelectedHeader>
          </ListHeaderContainer>
          <Sheet.Scroller draggableAt="both">
            <Sheet.Content>
              {/* Scrollable Content */}
              <ScrollableContent>
                {member ? (
                  <ListCard key={member.ExtKey} organisation={member} />
                ) : (
                  organisations?.map((organisation: Organisation, index: number) => (
                    <ListCard
                      key={`${organisation.Tel}-${index}`}
                      organisation={organisation}
                      onClick={() => selectMember(organisation)}
                    />
                  ))
                )}
              </ScrollableContent>
            </Sheet.Content>
          </Sheet.Scroller>
        </Sheet.Container>
      </Sheet>
    </ListContainer>
  );
};
