import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';
interface MainContainerProps {
  $positionFixed: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${breakpoints.desktopOrBigger} {
    width: 25rem;
    height: 100%;
  }

  ${breakpoints.combinedTablet} {
    width: 100%;
    height: ${(props) => (props.$positionFixed ? '100%' : 'none')};
  }
`;
