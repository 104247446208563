'use client';

import { ButtonProps } from './buttonType';
import { IconBox, IconButtonContainer } from './styles';

export const IconButton = ({ icon, iconColor, onClick }: ButtonProps) => {
  return (
    <IconButtonContainer onClick={onClick}>
      <IconBox $iconColor={iconColor}>{icon}</IconBox>
    </IconButtonContainer>
  );
};
