import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

interface SearchModalProps {
  $showFilterModal: boolean;
}

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  padding-inline: 1rem;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};

  ${breakpoints.combinedTablet} {
    align-items: center;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 3rem;
  padding-inline: ${(props) => props.theme.spacing(2)}px;
  padding-block: ${(props) => props.theme.spacing(1.5)}px;
  border-radius: ${(props) => props.theme.spacing(1)}px;
  border: 1px solid ${(props) => props.theme.colors.typographyBody};
  color: ${(props) => props.theme.colors.typographyBody};

  ${breakpoints.combinedTablet} {
    width: 100%;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.typographyBody};
  background-color: transparent;
`;

export const SearchPopup = styled.div<SearchModalProps>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  ${breakpoints.combinedTablet} {
    margin-top: ${(props) => (props.$showFilterModal ? '158' : '0')}px;
  }
`;

export const Scrollable = styled.div`
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
`;

export const SearchPopupResult = styled.ul`
  list-style-type: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  ${breakpoints.combinedTablet} {
    &:last-child {
      margin-bottom: 3rem;
    }
  }
`;

export const SearchHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  padding-inline: 1rem;
  padding-block: 1.5rem;
`;

export const SearchResultHeader = styled.span`
  font-size: ${(props) => props.theme.typography3.fontSize};
  line-height: ${(props) => props.theme.typography3.lineHeight};
  font-weight: ${(props) => props.theme.typography3.fontWeight};
  color: ${(props) => props.theme.colors.typographyHeading};
`;

export const SearchResult = styled.li`
  &::before {
    top: 0;
    left: 1rem;
    height: 1px;
    position: absolute;
    display: block;
  }
  margin-block: 1.5rem;
  color: ${(props) => props.theme.colors.typographyHeading};
`;

export const SearchResultContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ResultCityName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const CountryWrapper = styled.div`
  margin-left: 2.5rem;
`;

export const CityName = styled.span`
  font-size: ${(props) => props.theme.typography5.fontSize};
  line-height: ${(props) => props.theme.typography5.lineHeight};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
`;

export const CountryName = styled.span`
  font-size: ${(props) => props.theme.typography4.fontSize};
  line-height: ${(props) => props.theme.typography4.lineHeight};
  font-weight: ${(props) => props.theme.typography4.fontWeight};
  color: ${(props) => props.theme.colors.typographyBody};
`;
