import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const FilterContainer = styled.div`
  margin-top: 1.5rem;
  width: 23rem;
  ${breakpoints.combinedTablet} {
    margin-block: 1rem;
    width: 100%;
  }
`;

export const FilterModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-width: 23rem;
  left: 0;
  padding-block: 1rem;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};

  ${breakpoints.combinedTablet} {
    position: fixed;
    display: block;
    margin-top: 1rem;
    padding: 1rem;
    z-index: 1;
  }
`;

export const FilterLabelButtonWrapper = styled.div`
  padding-bottom: 1rem;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const FilterHeader = styled.span`
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};
`;
