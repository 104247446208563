import { Marker } from 'react-map-gl';

import LocationIcon from '@images/Location.svg';

import { lightTheme } from '@/styles/theme';

import { MarkerContent } from './styles';

interface MapMarkerProps {
  latitude: number;
  longitude: number;
  onClick?: () => void | undefined;
  isSelected?: boolean;
}

/**
 * Marker for a single organization location
 */

export const MapMarker = ({ latitude, longitude, onClick, isSelected }: MapMarkerProps) => (
  <Marker latitude={latitude} longitude={longitude} anchor="bottom">
    <MarkerContent onClick={onClick}>
      <LocationIcon fill={isSelected ? lightTheme.colors.secondary : lightTheme.colors.primary} />
    </MarkerContent>
  </Marker>
);
