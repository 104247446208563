'use client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'next/image';

import MenuIcon from '@images/Menu.svg';
import MenuIconClose from '@images/MenuClose.svg';

import { IconButton } from '@/components/Button';
import { useIsMobileOrTablet } from '@/hooks/useIsMobileOrTablet';
import { lightTheme } from '@/styles/theme';
import { getEnvUrl } from '@/utils/headerUtils';

import {
  BecameMember,
  ButtonsContainer,
  HeaderButton,
  HeaderButtonsContainer,
  HeaderContainer,
  HomeButton,
  LoginButton,
  LogoContainer,
  MobileNav,
} from './styles';

export const Header = () => {
  const isSmallScreen = useIsMobileOrTablet();
  const { t } = useTranslation('common');
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  // Extract header buttons from the translation file for desktop
  const headerButtons = t('headerButtons', { returnObjects: true });

  // Add Login and Become Member buttons to headerButtons for mobile only
  const extendedHeaderButtons = {
    ...headerButtons,
    Login: t('Login'),
    BecameMember: t('BecameMember'),
  };

  // Toggle the burger menu
  const toggleBurgerMenu = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <HomeButton href={process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}>
          <Image src={'/assets/images/Logo.svg'} fill alt={t('logoAlt')} priority />
        </HomeButton>
      </LogoContainer>
      <HeaderButtonsContainer>
        {Object.entries(headerButtons).map(([key, value]) => (
          <HeaderButton key={key} href={getEnvUrl(key)}>
            {value}
          </HeaderButton>
        ))}
      </HeaderButtonsContainer>
      <ButtonsContainer>
        <LoginButton href={getEnvUrl('Login')}>{t('Login')}</LoginButton>
        <BecameMember href={getEnvUrl('BecameMember')}>{t('BecameMember')}</BecameMember>
      </ButtonsContainer>
      {/* Mobile Menu */}
      {isSmallScreen && (
        <IconButton
          icon={isBurgerOpen ? <MenuIconClose /> : <MenuIcon />}
          iconColor={lightTheme.colors.typographyHeading}
          onClick={toggleBurgerMenu}
        />
      )}
      <MobileNav $isOpen={isBurgerOpen} aria-hidden={!isBurgerOpen}>
        {Object.entries(extendedHeaderButtons).map(([key, value]) => (
          <HeaderButton key={key} href={getEnvUrl(key)}>
            {value as string}
          </HeaderButton>
        ))}
      </MobileNav>
    </HeaderContainer>
  );
};
