import { useEffect, useState } from 'react';

/**
 * A hook to detect if the current screen width is less than or equal to 1024px.
 */
export const useIsMobileOrTablet = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia('(max-width: 1024px)');
      setIsSmallScreen(mediaQuery.matches);
      const listener = () => {
        setIsSmallScreen(mediaQuery.matches);
      };
      mediaQuery.addListener(listener);
      return () => {
        mediaQuery.removeListener(listener);
      };
    }
  }, []);

  return isSmallScreen;
};
