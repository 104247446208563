/**
 * Represents an API error with a message and status code.
 */
export class ApiError extends Error {
  constructor(
    public message: string,
    public status: number,
  ) {
    super(message);
  }
}
