'use client';

import LabelIcon from '@images/Tag.svg';

import { IconContainer, LabelContainer } from './styles';

export interface LabelProps {
  label?: string;
}

export const Label = ({ label }: LabelProps) => {
  return (
    <LabelContainer>
      {
        <IconContainer>
          <LabelIcon />
        </IconContainer>
      }
      {label}
    </LabelContainer>
  );
};
